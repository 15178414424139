import React from "react"
import Layout from "../components/layout/layout"
import TableOfContents from "../components/homepage/table-of-contents"
import SEO from "../components/layout/seo"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, withPrefix, Link } from "gatsby"

export default function ErrorPage({ data }) {
  const seo = {
    title: "404 - strona której szukasz nie istnieje",
    description: "404 - strona której szukasz nie istnieje, przejdź na stronę główną lub skorzystaj z menu.",
    url: "https://medicusdukla.pl/"
  }

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <section className={"o-error-page"}>
        <div data-g="container" className="u-text-center">
          <img src="/images/404.svg" alt="404 obrazek" />
          <p>
            Wygląda na to, że strona, której szukasz, nie istnieje.
          </p>
          <Link to="/" className="a-button-border">
            Wróć na stronę główną
          </Link>
        </div>
      </section>
    </Layout>
  )
}
